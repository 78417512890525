import React from "react"
import Layout from "../components/Layout"
import { Box, Flex, Text, Button, Heading } from "rebass"
import { Label, Input, Textarea } from "@rebass/forms"
import { Helmet } from "react-helmet"

export default function Contact() {
  return (
    <Layout>
      <Helmet>
        <title>Plank Contact page</title>
      </Helmet>
      <Flex flexDirection="row" justifyContent="center" color="secondary">
        <Flex flexDirection="column" mb={8} px={[4, 0]}>
          <Heading fontSize={6} mb={6} color="secondary" textAlign="center">
            Contact
          </Heading>
          <Text variant="primaryText" color="secondary">
            Feel free to contact us with any enquiries using the appended form.
          </Text>
          <Text variant="primaryText" color="secondary">
            Alternatively, feel free to email us at info@plank.ai or call us at:
          </Text>
          <Text variant="primaryText" color="secondary" mb={4}>
            {" "}
            +44 (0)7921 085400
          </Text>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <Box>
              <Label htmlFor="name">Name:</Label>
              <Input type="text" id="name" name="name" borderRadius="8" />
            </Box>
            <Box>
              <Label htmlFor="email">Email:</Label>
              <Input type="email" id="email" name="email" />
            </Box>
            <Box mb={16}>
              <Label htmlFor="message">Message:</Label>
              <Textarea id="message" name="message"></Textarea>
            </Box>
            <Box>
              <Button
                fontSize={2}
                type="submit"
                variant="contact"
                sx={{ width: "100%", cursor: "pointer" }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Flex>
      </Flex>
    </Layout>
  )
}
